import React, { useContext } from "react"
import { PageContext } from "../../../context/PageContext"
import { CrmFieldErrors, fieldError } from "../../../hooks/useCrmApi"
import useDatoMicrocopy from "../../../hooks/useDatoMicrocopy"
import Button from "../../atoms/Button"
import Checkbox from "../../atoms/form/Checkbox"
import Fieldset from "../../atoms/form/Fieldset"
import { OptionProps } from "../../atoms/form/Option"
import Select from "../../atoms/form/Select"
import TextArea from "../../atoms/form/TextArea"
import LoadingSpinner from "../../atoms/LoadingSpinner"
import CountrySelect from "./subcomponents/CountrySelect"
import UserContactFieldset from "./subcomponents/UserContactFieldset"
// import * as styles from "./ContactB2B.module.scss"

/**
 * "I'm interested in" dropdown values.
 * These must match keys in `interestSelect` microcopy entry
 * https://harvia.admin.datocms.com/editor/item_types/1172670/items/89773347/edit
 */
export const clientContactInterests = [
  "woodHeaters",
  "electricHeaters",
  "saunaCabins",
  "saunaInteriors",
  "spaModules",
  "saunaAccessories",
  "other",
] as const

type ContactB2BProps = {
  formRef: React.LegacyRef<HTMLFormElement>
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  loading: boolean
  errors?: CrmFieldErrors
}

const ContactB2B = (props: ContactB2BProps) => {
  const { locale } = useContext(PageContext)
  const t = useDatoMicrocopy("formContact", locale)

  const defaultErrorMsg = t("default", "Invalid value", "formErrors")

  const interestOptions: OptionProps[] = clientContactInterests.map(opt => ({
    value: opt,
    label: t(opt, opt, "interestSelect"),
  }))
  interestOptions.unshift({ value: "", label: t("preselect", "Choose your topic...", "interestSelect") })

  const getErrorMsg = (field: string): string | undefined => {
    const error = fieldError(props.errors, field)
    if (error) {
      return error.type ? t(error.type, defaultErrorMsg, "formErrors") : defaultErrorMsg
    }
    return
  }

  return (
    <form ref={props.formRef} onSubmit={props.onSubmit} name="contact-b2b">
      <Fieldset legend={t("legend.topic", "Topic")}>
        <Select
          name="interest"
          label={t("interest.label", "I'm interested in", "formCommon")}
          options={interestOptions}
          errorMsg={getErrorMsg("interest")}
        />
        <TextArea
          name="message"
          label={t("message.label", "Your message", "formCommon")}
          placeholder={t("message.placeholder", "Type your message here", "formCommon")}
          errorMsg={getErrorMsg("message")}
        />
      </Fieldset>

      <UserContactFieldset errors={props.errors}>
        <CountrySelect
          name="country"
          label={t("country.label", "Country")}
          preselectLabel={`${t("country.label", "Country")}...`}
          required={true}
          errorMsg={getErrorMsg("country")}
        />
      </UserContactFieldset>

      <Checkbox
        name="consent-pii-data"
        label={
          <span>
            {t(
              "consent-pii-data.label",
              "I agree to the storage and processing of my personal data (required).",
              "formCommon"
            )}{" "}
            <a href={t("consent-pii-data.labelUrlPath", "/en/register-description", "formCommon")} target="_blank">
              {t("consent-pii-data.labelUrlText", "Register description", "formCommon")}
            </a>
          </span>
        }
        required={true}
        errorMsg={getErrorMsg("consent-pii-data")}
      />
      <Button label={t("sendButton.label", "Approve & send", "formCommon")} type="submit" disabled={props.loading} />
      {props.loading && <LoadingSpinner loading={props.loading} />}
    </form>
  )
}

export default ContactB2B
